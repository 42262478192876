<template>
  <div class="not-tip">
    <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/coupon/is-not.png" mode="widthFix" class="not-tip-image" />
    <span class="not-tip-message">{{message}}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "没有数据"
    }
  }
}
</script>

<style lang="scss" scoped>
.not-tip {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #5d5d5d;
  flex-direction: column;
  padding: 100px 0;
}
.not-tip .not-tip-image {
  width: 140px;
}
.not-tip .not-tip-message {
  margin-top: 20px;
}
</style>