<template>
  <div>
    <NavHeader title="兑换中心"></NavHeader>
    <div class="content">
      <div class="coupon-top">
        <div class="title">当前邻里值</div>
        <div class="integral">{{user_info.reward}}</div>
        <div class="bnt-wrap">
          <div class="detail" @click="rewardTap">邻里值明细</div>
          <div class="cut-off"></div>
          <div class="record" @click="recordTap">兑换记录</div>
        </div>
      </div>

      <div class="main-wrap">
        <div class="title-wrap" @click="typeTap">
          <div v-for="(item,index) in typeList" :key="index">
            <div v-if="item.category_id == category_id">
              <span class="title">{{item.name}}</span>
              <span class="iconfont iconfanhui-copy"></span>
            </div>
          </div>
        </div>
        <div class="type-list" v-if="showType">
          <div v-for="(item,index) in typeList" :key="index">
            <div class="type-item" :class="item.category_id == category_id?'checked':''" @click="typeItemTap(item.category_id)">
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="goods-wrap" :class="showType?'show-type':''" v-if="!notGoods">
          <van-list class="goods-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="flipOver" :immediate-check="false" :offset="50">
            <div class="goods-item" @click="goodsTap(item.product_id)" v-for="(item,index) in goodsList" :key="index">
              <div class="image">
                <img mode="widthFix" :src="item.original_image" />
              </div>
              <div class="name-wrap">
                <div class="name">{{item.name}}</div>
                <div class="integral-wrap">
                  <span class="integral">{{item.points}}</span>
                  <span>邻里值</span>
                </div>
              </div>
            </div>
          </van-list>

          <div class="overlay" @click="overlayTap"></div>

        </div>
        <div class="not-goods" v-if="notGoods">
          <XyNotTip message="暂无可兑换商品"></XyNotTip>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavHeader from '@/components/nav-header.vue'
import XyNotTip from '@/components/xy-not-tip.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'

export default {
  name:"exchangeList",
  data () {
    return {
      typeList: [],
      goodsList: [],
      notGoods: false,
      showType: false,
      category_id: 0,
      per_page: 10,
      page: 1,
      loading: false,
      finished: false
    }
  },
  components: {
    NavHeader,
    Footer,
    XyNotTip,
  },
  created () {
    this.getCategories();
    this.$store.dispatch('updateMember')
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    category_id: function (e) {
      if (e) {
        this.goodsList = []
        this.finished = false
        this.notGoods = false
        this.page = 1
        this.getGoodsList()
      }
    },
  },
  methods: {
    getCategories: function () {
      let that = this;
      that.$axios.get(that.ocApi + 'points/categories', {})
        .then(res => {
          if (res.data.status == 1) {
            that.typeList = res.data.data.children;
            that.category_id = res.data.data.children[0].category_id;
          }
        })
        .catch(error => {
          window.console.log(error)
        });
    },
    flipOver: function () {
      if (this.category_id) {
        this.page += 1
        this.getGoodsList()
      }
    },
    getGoodsList: function () {
      let that = this;
      let category_id = that.category_id;
      let page = that.page;
      let per_page = that.per_page;
      that.$axios.get(that.ocApi + 'points/products', {
        params: {
          per_page,
          category_id,
          page
        }
      })
        .then(res => {
          if (res.data.current_page >= res.data.last_page) {
            that.finished = true
          }
          if (res.data.total == 0) {
            that.notGoods = true
            return;
          }
          let goodsListData = res.data.data;
          let goodsList = that.goodsList;
          goodsList.push(...goodsListData)
          that.goodsList = goodsList
          that.loading = false
        })
        .catch(error => {
          window.console.log(error)
        });
    },
    rewardTap: utils.debounce(function () {
      this.$router.push({ name: 'integralHistory' });
    }, 200, true),
    recordTap: utils.debounce(function () {
      this.$router.push({ name: 'exchangeHistory' });
    }, 200, true),
    goodsTap: utils.debounce(function (id) {
      this.$router.push({ name: 'exchangeDetail', params: { goodsId: id } });
    }, 200, true),
    typeTap: utils.debounce(function () {
      this.showType = (!this.showType)
    }, 200, true),
    overlayTap: utils.debounce(function () {
      this.showType = false
    }, 200, true),
    typeItemTap: utils.debounce(function (category_id) {
      this.category_id = category_id
      this.showType = false
    }, 200, true),
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 46px;
  background-color: #f7f7ff;
  .coupon-top {
    padding: 20px 21px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    background-image: url('https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/exchange/index-bg.png');
    background-position: 100% 100%;
    background-size: 105px;
    background-repeat: no-repeat;
    .title {
      font-size: 12px;
      color: #333333;
      line-height: 12px;
    }
    .integral {
      font-size: 34px;
      line-height: 34px;
      margin-top: 23px;
      color: #10dfbd;
      font-weight: bold;
    }
    .bnt-wrap {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-top: 24px;
      display: flex;
      .cut-off {
        width: 1px;
        height: 12px;
        background-color: #333333;
        margin: 0 10px;
      }
    }
  }

  .main-wrap {
    margin-top: 10px;
    width: 100%;
    .title-wrap {
      width: 100%;
      padding: 18px 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background-color: #fff;
      .title {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        line-height: 16px;
      }
      .iconfont {
        font-size: 8px;
        color: #a5a5a5;
        margin-left: 7px;
      }
    }
    .type-list {
      position: absolute;
      width: 100%;
      z-index: 99;
      background-color: #fff;
      .type-item {
        width: 100%;
        padding: 18px 15px;
        box-sizing: border-box;
        font-size: 14px;
        color: #333333;
        background-color: #fff;
        &.checked {
          background-color: #f6f6f6;
        }
      }
    }
    .goods-wrap {
      width: 100%;
      position: relative;
      background-color: #fff;
      &.show-type .overlay {
        content: ' ';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9;
      }
      .goods-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .goods-item {
          width: 50%;
          padding: 10px;
          box-sizing: border-box;
          .image {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .name-wrap {
            padding: 11px 0;
            .name {
              font-size: 14px;
              color: #333333;
              line-height: 14px;
            }
            .integral-wrap {
              font-size: 12px;
              color: #999999;
              margin-top: 13px;
              .integral {
                font-size: 18px;
                font-weight: bold;
                color: #10dfbd;
              }
            }
          }
        }
        :deep(.van-list__loading),
        :deep(.van-list__finished-text),
        :deep(.van-list__error-text) {
          width: 100%;
        }
      }
      .ismore {
        width: 100%;
        text-align: center;
        font-size: 10px;
        color: #999999;
        padding: 5px 0 18px;
        box-sizing: border-box;
      }
    }
  }
}
</style>