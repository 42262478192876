<template>
  <div>
    <van-nav-bar :left-text="title" left-arrow class="nav" @click-left="onClickLeft" fixed/>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '小院生活'
    }
  },
  data () {
    return {

    }
  },
  components: {

  },
  methods: {
    onClickLeft: function () {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.nav {
  :deep(.van-icon,)
  .van-nav-bar__text {
    color: #333333;
  }
}
</style>